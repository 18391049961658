import { ONLY_TRACKING_ACCOUNTS } from '../utils/constants';
import { DefaultTheme } from 'styled-components';
import { Theme, createTheme } from '@material-ui/core/styles';

const defaultTheme: DefaultTheme = {
	appFont: 'Inter, Helvetica, Arial, sans-serif',
	appFontMedium: 'Inter',
	appFontHeading: 'Inter Bold',
	appFontBold: 'Inter Bold',
	appFontSemiBold: 'Inter SemiBold',
	appFontLight: 'Inter Light',
	textColor: '#54565a',
	titleColor: '#54565a',
	fontSizeDefault: '16px',
	fontSizeHeading: '18px',
	fontSizeSubHeading: '16px',
	fontSizeForm: '16px',
	fontSizeBtnFooter: '16px',
	primaryColor: '#F15B2B',
	secondaryColor: '#eea590',
	gray: '#cbcbcb',
	light: '#ffffff',
	dark: '#000000',
	altGray: '#adadad',
	defaultBtnFontColor: '#52565a',
	defaultBtnBackgroundColor: '#e7ebec',
	defaultBtnBorderColor: '#e7ebec',
	defaultBtnBorderWidth: '1px',
	secondaryBtnFontColor: '#52565a',
	secondaryBtnBackgroundColor: '#e7ebec',
	secondaryBtnBorderColor: '#e7ebec',
	secondaryBtnBorderWidth: '1px',
	lineGray: '#CAD3DA',
	disableGray: '#f1f3f7',
	appBackgroundColor: '#F8F8F8',
	headerBackgroundColor: '#FFFFFF',
	splashBackgroundColor: '#FFFFFF',
};

const csmTheme = {
	appFont: 'Frutiger',
	appFontHeading: 'Frutiger Bold',
	appFontBold: 'Frutiger Bold',
	appFontLight: 'Frutiger',
	textColor: '#808080',
	titleColor: '#808080',
	primaryColor: '#006c85',
	secondaryColor: '#006c85',
	appBackgroundColor: '#F2F8F9',
	splashBackgroundColor: '#F2F8F9',
	headerBackgroundColor: '#F2F8F9',
};

const lafiseTheme = {
	primaryColor: '#0a3e2a',
	secondaryColor: '#0a3e2a',
};

const suraTheme = {
	primaryColor: '#023598',
	secondaryColor: '#023598',
	textColor: '#484649',
	titleColor: '#484649',
};

const qualitasTheme = {
	primaryColor: '#792a82',
	secondaryColor: '#792a82',
};

const assaTheme = {
	textColor: '#484649',
	titleColor: '#484649',
	primaryColor: '#203a6e',
	secondaryColor: '#203a6e',
};

const humanAdvantageTheme = {
	primaryColor: '#93c855',
	secondaryColor: '#00b04f',
};

const mmmTheme = {
	primaryColor: '#006566',
	secondaryColor: '#006566',
	appFont: 'Tahoma',
	fontSizeHeading: '18px',
	fontSizeSubHeading: '14px',
	appFontHeading: 'Tahoma Bold',
	appFontBold: 'Tahoma Bold',
	appFontMedium: 'Tahoma',
	appFontSemiBold: 'Tahoma Bold',
	appFontLight: 'Tahoma',
	textColor: '#54565f',
	titleColor: '#212121',
	fontSizeForm: '14px',
	fontSizeDefault: '14px',
};

const hondaTheme = {
	primaryColor: '#246cba',
	secondaryColor: '#246cba',
};

const acuraTheme = {
	primaryColor: '#000000',
	secondaryColor: '#54565a',
	textColor: '#54565a',
	titleColor: '#54565a',
};

const mazdaTheme = {
	primaryColor: '#262626',
	secondaryColor: '#ff1d25',
};

const sssAutoTheme = {
	primaryColor: '#00257A',
	secondaryColor: '#e7f2f7',
	btnColor: '#0088CE',
	backgroundTrackingSteps: '#0088CE',
};

const banescoTheme = {
	primaryColor: '#003C71',
	secondaryColor: '#1A61AB',
	btnColor: '#003C71',
	backgroundTrackingSteps: '#003C71',
};

const acertaTheme = {
	primaryColor: '#827c78',
	secondaryColor: '#A7A8A9',
	btnColor: '#827c78',
	backgroundTrackingSteps: '#FF7546',
};

const lincolnTheme = {
	primaryColor: '#293338',
	secondaryColor: '#919498',
	btnColor: '#F26147',
	backgroundTrackingSteps: '#F26147',
};

const nissanTheme = {
	primaryColor: '#000000',
	secondaryColor: 'rgba(145,148,152,0.39)',
	btnColor: '#C3002F',
	backgroundTrackingSteps: '#C3002F',
	animationsColor: '#C3002F',
};

const segurosMundialTheme = {
	primaryColor: '#0A99D6',
	secondaryColor: '#ffffff',
	btnColor: '#0A99D6',
	backgroundTrackingSteps: '#0A99D6',
};

const ricardoPerezTheme: Partial<DefaultTheme> = {
	primaryColor: '#000000',
	secondaryColor: '#e5e5e5',
	btnColor: '#eb0a1e',
	backgroundTrackingSteps: '#eb0a1e',
	secondaryBtnFontColor: '#000',
	secondaryBtnBackgroundColor: '#fff',
	secondaryBtnBorderColor: '#000',
};

const optimaSegurosTheme = {
	primaryColor: '#FFC72C',
	secondaryColor: '#ffe9a9',
	btnColor: '#FFC72C',
	backgroundTrackingSteps: '#FFC72C',
};

const kiaTheme = {
	primaryColor: '#36434c',
	secondaryColor: '#ebeced',
	btnColor: '#36434c',
	backgroundTrackingSteps: '#36434c',
};

const lexusTheme = {
	primaryColor: '#000000',
	secondaryColor: '#e5e5e5',
	btnColor: '#000000',
	backgroundTrackingSteps: '#000000',
};

const infinitiTheme = {
	primaryColor: '#323232',
	secondaryColor: '#eaeaea',
	btnColor: '#323232',
	backgroundTrackingSteps: '#323232',
};

const mitsubishiTheme = {
	primaryColor: '#ed0009',
	secondaryColor: '#fde5e5',
	btnColor: '#000000',
	backgroundTrackingSteps: '#ed0009',
};

const hyundaiTheme = {
	primaryColor: '#1d87ef',
	secondaryColor: '#e8f3fd',
	btnColor: '#002c5f',
	backgroundTrackingSteps: '#1d87f0',
};

const toyotaTheme = {
	primaryColor: '#000000',
	secondaryColor: '#e5e5e5',
	btnColor: '#cc0100',
	backgroundTrackingSteps: '#cc0100',
};

const generalDeSegurosTheme = {
	primaryColor: '#004B9B',
	secondaryColor: 'rgba(0,75,155,0.09)',
	btnColor: '#FF7D23',
	backgroundTrackingSteps: '#FF7D23',
	loaderColor: '#FF7D23',
};

const internacionalDeSegurosTheme = {
	primaryColor: '#009B3A',
	secondaryColor: '#f8f8ff',
	btnColor: '#009B3A',
	backgroundTrackingSteps: '#009B3A',
};

const hdiTheme = {
	primaryColor: '#66a519',
	secondaryColor: '#e5f0e8',
	btnColor: '#66a519',
	backgroundTrackingSteps: '#66a519',
};

const fedpaTheme = {
	primaryColor: '#113a7b',
	secondaryColor: '#e7ebf1',
	btnColor: '#222222',
	backgroundTrackingSteps: '#113a7b',
};

const kintoOneTheme = {
	primaryColor: '#00708f',
	secondaryColor: '#00708f',
	btnColor: '#002732',
	backgroundTrackingSteps: '#00708f',
};

const quanTheme = {
	primaryColor: '#5f24d1',
	secondaryColor: '#eea590',
	btnColor: '#5f24d1',
	backgroundTrackingSteps: '#5f24d1',
};

const ascTheme = {
	primaryColor: '#d8441c',
	secondaryColor: '#d09115',
	btnColor: '#d8441c',
	backgroundTrackingSteps: '#d09115',
};

const autochilangoTheme = {
	primaryColor: '#0B1928',
	secondaryColor: '#0B1928',
	btnColor: '#0B1928',
	backgroundTrackingSteps: '#15E0E1',
};

const eparkTheme = {
	primaryColor: '#1394b3',
	secondaryColor: '#1394b3',
	btnColor: '#1394b3',
	backgroundTrackingSteps: '#1394b3',
};

const bacTheme = {
	primaryColor: '#DC1431',
	secondaryColor: '#DC1431',
	btnColor: '#1394b3',
	backgroundTrackingSteps: '#DC1431',
};


interface Account {
	[key: string]: Partial<DefaultTheme>;
}

const themeByAccounts: Account = {
	sura: suraTheme,
	kavak: suraTheme,
	auteco: suraTheme,
	mazda: mazdaTheme,
	acura: acuraTheme,
	honda: hondaTheme,
	qualitas: qualitasTheme,
	assa: assaTheme,
	lafise: lafiseTheme,
	mmm: mmmTheme,
	'mmm-empleados': mmmTheme,
	'sura-co': suraTheme,
	'inspecciones-csm': csmTheme,
	'multiple-asistencia-en-viaje': csmTheme,
	'multiple-asistencia-en-el-hogar': csmTheme,
	'human-advantage': humanAdvantageTheme,
	'sss-auto': sssAutoTheme,
	'sss-home': sssAutoTheme,
	banesco: banescoTheme,
	acerta: acertaTheme,
	lincoln: lincolnTheme,
	'seguros-mundial': segurosMundialTheme,
	'ricardo-perez': ricardoPerezTheme,
	'optima-seguros': optimaSegurosTheme,
	kia: kiaTheme,
	lexus: lexusTheme,
	infiniti: infinitiTheme,
	mitsubishi: mitsubishiTheme,
	hyundai: hyundaiTheme,
	toyota: toyotaTheme,
	nissan: nissanTheme,
	'general-de-seguros': generalDeSegurosTheme,
	'internacional-de-seguros': internacionalDeSegurosTheme,
	hdi: hdiTheme,
	fedpa: fedpaTheme,
	'kinto-one': kintoOneTheme,
	quan: quanTheme,
	asc: ascTheme,
	autochilango: autochilangoTheme,
	epark: eparkTheme,
	bac: bacTheme,
};

export const getThemeByAccount = (account: string): DefaultTheme => {
	const isTrackingRoute = document.getElementsByClassName('tracking-route');
	if (ONLY_TRACKING_ACCOUNTS.includes(account) && !isTrackingRoute?.length) {
		account = 'default';
	}
	const currentTheme = themeByAccounts[account] || {};
	return { ...defaultTheme, ...currentTheme };
};

export const buildMaterialUITheme = (globalStyleProps: DefaultTheme): Theme => {
	const materialUITheme = createTheme({
		palette: {
			primary: {
				main: globalStyleProps.primaryColor,
			},
			secondary: {
				main: globalStyleProps.secondaryColor,
			},
		},
	});

	return materialUITheme;
};
